<template>
    <div class="shop_boxs">
        <header class="shop_head">
            <header class="about_head">
                <img src="@/assets/shop/shop.png" class="img_css" />
            </header>
        </header>
        <main class="shop_main">
            <div class="type_head">
              <div class="item_type"  v-for="(item,index) in shopListType" :key="index" :class="{'select_css': selecType == index}" @click="addTypeChange(index,item)">{{item.name}}</div>
            </div>
            <div class="type_mian">
                <div v-for="(item, index) in foodList" :key="index" class="item_list">
                    <img :src="imgUrl+item.coverPics" :alt="item.name" class="item_img">
                    <div class="item_name">{{ item.name }}</div>
                </div>
            </div>
        </main>
    </div>
</template>

<script setup>
import { ref } from "vue";
import {getShopType,getSpecialShopsWeb,imgUrl} from '@/api/index';

const selecType = ref(0)
// 图书列表
const foodList = ref([
    // {
    //     img: require("@//assets/shop/food1.png"),
    //     name: "四四慢食厨房"
    // },
    // {
    //     img: require("@//assets/shop/food2.png"),
    //     name: " Arabica"
    // },
    // {
    //     img: require("@//assets/shop/food3.png"),
    //     name: "MONA"
    // },
    // {
    //     img: require("@//assets/shop/food4.png"),
    //     name: "京A"
    // },
    // {
    //     img: require("@//assets/shop/food5.png"),
    //     name: "PI CHOCO"
    // },
    // {
    //     img: require("@//assets/shop/food6.png"),
    //     name: "十一寸"
    // },
    // {
    //     img: require("@//assets/shop/food7.png"),
    //     name: "宴锦堂"
    // },
    // {
    //     img: require("@//assets/shop/food8.png"),
    //     name: "椒小厨"
    // },
    // {
    //     img: require("@//assets/shop/food9.png"),
    //     name: "最喜"
    // },
    // {
    //     img: require("@//assets/shop/food10.png"),
    //     name: "SUSU"
    // },
    // {
    //     img: require("@//assets/shop/food11.png"),
    //     name: "HALF"
    // },
    // {
    //     img: require("@//assets/shop/food12.png"),
    //     name: "小甜浆"
    // },

])
const shopListType = ref([])

function addTypeChange (val,item) {
    selecType.value = val;
    DataSpecialShopsWeb(item.id)

}
//分类
const getShopTypeList = () => {
  getShopType().then(res=>{
    if(res.code==1){
      // console.log(res.data);
      DataSpecialShopsWeb(res.data[0].id)
      shopListType.value=res.data;
    }
  })
}
//店铺列表
const DataSpecialShopsWeb = (type) => {
  getSpecialShopsWeb({typeId:type, category:1}).then(res=>{
      if(res.code==1){
        // console.log(res.data);
        foodList.value=res.data;
      }
  })
}
getShopTypeList()
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 1536px) {
  .shop_main{
    width: 1200px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .shop_main{
    width: 950px !important;
  }
}
@media only screen and (max-width: 960px) {
  .shop_main{
    width: 850px !important;
  }
}
@media only screen and (max-width: 768px) {
  .shop_main{
    width: 680px !important;
  }
}
@media only screen and (max-width: 640px) {
  .shop_main{
    width: 560px !important;
  }
}
</style>

<style lang="scss" scoped>
.shop_boxs {
    font-family: PingFang SC;
    z-index: 1;
    max-width: 1920px;
    margin: 0 auto;
    .shop_head {
        // border: 30px solid #ffda00;

        .img_css {
        width: 100%;
        display: flex;
    }
    }

    .shop_main {
        // padding: 81px 250px 87px 230px;
        padding: 81px 0 87px;
        width: 1400px;
        margin: 0 auto;
        .type_head {
            display: flex;
            justify-content: center;
            cursor: pointer;
            .item_type {
                width: 180px;
                height: 64px;
                background: #FFFFFF;
                border: 1px solid #8C857B;
                font-size: 20px;
                font-weight: 300;
                color: #8C857B;
                text-align: center;
                line-height: 64px;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .select_css {
                background: #8C857B;
                font-size: 26px;
                color: #FFFFFF;
            }
        }

        .type_mian {
            margin-top: 53px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .item_list {
                height: 252px;
                position: relative;
                margin-right: 20px;
                margin-bottom: 19px;
                width: 23.5%;
                &:nth-child(4n) {
                   margin-right: 0;
                }

                .item_img {
                    // width: 335px;
                    // height: 252px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                }

                .item_name {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 19px 0 21px 21px;
                    background: rgb(140 133 123 / 80%);
                    box-shadow: 0px 2px 20px 0px rgba(183,183,183,0.2);
                    font-size: 26px;
                    font-weight: 400;
                    color: #FFFFFF;
                    box-sizing: border-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .wework_box {
            position: relative;

            .wework_img {
                width: 1056px;
                height: 792px;
                display: flex;
            }

            .wework_min_box {
                position: absolute;
                right: -340px;
                bottom: 0;
                width: 565px;
                height: 219px;
                background: #8C857B;

                .wework1_img {
                    width: 200px;
                    height: 45px;
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}
</style>
